/**@jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { Spinner } from "theme-ui";
import { Layout } from "../components/layout/main-layout";
import { Seo } from "../components/seo/seo";

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          title
          author
          keywords
          siteUrl
        }
      }
      logo: file(relativePath: { eq: "praxisnet.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  useEffect(() => {
    const languages = navigator ? navigator.languages : null;
    if (languages) {
      if (languages.includes("es")) {
        navigate("/es");
        return;
      }
      if (languages.includes("en")) {
        navigate("/en");
        return;
      }
      navigate("/es");
    }
  }, []);

  const { description, siteUrl } = data.site.siteMetadata;

  const logoImage = {
    src: `${siteUrl}${data.logo.childImageSharp.fluid.src}`,
    height: 300,
    width: 300,
  };

  return (
    <Layout>
      <Seo
        lang="es"
        title="Praxisnet"
        description={description}
        image={logoImage}
      />
      <div
        sx={{
          display: "grid",
          placeContent: "center",
          height: ["calc(100vh - 128px)", null, null, "calc(100vh - 176px)"],
        }}
      >
        <Spinner size={128} sx={{ color: "primary" }} />
      </div>
    </Layout>
  );
}
